import React, { Component } from "react";
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import "../styles/forms.scss";

const InputBottomGraphic = () => (
    <svg
        className="graphic graphic--nao"
        width="300%"
        height="100%"
        viewBox="0 0 1200 60"
        preserveAspectRatio="none"
    >
        <path d="M0,56.5c0,0,298.666,0,399.333,0C448.336,56.5,513.994,46,597,46c77.327,0,135,10.5,200.999,10.5c95.996,0,402.001,0,402.001,0">
        </path>
    </svg>
);

const FormErrors = ({formErrors, intl}) => {
    return (
        <div className='input'>
            {Object.keys(formErrors).map((fieldName, i) => {
                if(formErrors[fieldName].length > 0){
                    return (
                        <small className="input--error" key={i}>{intl.formatMessage({id: `contact.${fieldName}`})} {formErrors[fieldName]}</small>
                    )
                } else {
                    return '';
                }
            })}
        </div>
    );
};

class ContactForm extends Component {

    constructor (props) {
        super(props);
        this.state = {
            name: '',
            email: '',
            message: '',
            formErrors: {},
            formValid: false
        };
    }

    handleUserInput (e) {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({[name]: value}, () => { this.validateField(name, value) });
    }

    validateField (name, value) {
        let fieldValidationErrors = this.state.formErrors;
        let emailValid = this.state.emailValid;
        let nameValid = this.state.nameValid;
        let messageValid = this.state.messageValid;
        const { intl } = this.props;

        switch(name) {
            case 'email':
                emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                fieldValidationErrors.email = emailValid ? '' : intl.formatMessage({id: 'contact.error-email'});
                break;
            case 'name':
                nameValid = value.length >= 2;
                fieldValidationErrors.name = nameValid ? '': intl.formatMessage({id: 'contact.error-name'});
                break;
            case 'message':
                messageValid = value.length >= 2;
                fieldValidationErrors.message = messageValid ? '': intl.formatMessage({id: 'contact.error-message'});
                break;
            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            emailValid: emailValid,
            nameValid: nameValid,
            messageValid: messageValid
        }, this.validateForm);
    }

    validateForm () {
        this.setState({formValid: this.state.emailValid && this.state.nameValid && this.state.messageValid});
    }

    render() {
        const { intl } = this.props;
        const sendButtonText = intl.formatMessage({id: 'contact.send'});

        return (
            <form
                className="contact__form"
                name="contact"
                method="post"
                action="/contactSuccess"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
            >
                <input type="hidden" name="form-name" value="contact" />
                <div className={`input input--nao ${this.state.name ? 'input--filled' : ''}`}>
                    <input className="input__field input__field--nao" name="name" type="text" id="name" value={this.state.name} onChange={(event) => this.handleUserInput(event)}/>
                    <label className="input__label input__label--nao" htmlFor="name">
                        <span className="input__label-content input__label-content--nao"><FormattedMessage id="contact.name" /></span>
                    </label>
                    <InputBottomGraphic />
                </div>
                <div className={`input input--nao ${this.state.email ? 'input--filled' : ''}`}>
                    <input className="input__field input__field--nao" name="email" type="text" id="email" value={this.state.email} onChange={(event) => this.handleUserInput(event)}/>
                    <label className="input__label input__label--nao" htmlFor="email">
                        <span className="input__label-content input__label-content--nao"><FormattedMessage id="contact.email" /></span>
                    </label>
                    <InputBottomGraphic />
                </div>
                <div className={`input input--nao ${this.state.message ? 'area--filled' : ''}`} >
                    <textarea
                        className="area__field area__field--nao"
                        name="message"
                        type="message"
                        id="message"
                        rows="5"
                        value={this.state.message}
                        onChange={(event) => this.handleUserInput(event)}
                    />
                    <label className="area__label area__label--nao" htmlFor="message">
                        <span className="area__label-content input__label-content--nao"><FormattedMessage id="contact.message" /></span>
                    </label>
                    <InputBottomGraphic />
                </div>
                <FormErrors formErrors={this.state.formErrors} intl={intl}/>
                <input
                    type="submit"
                    value={sendButtonText}
                    className="button"
                    disabled={!this.state.formValid}
                />
            </form>
        );
    }
}

ContactForm.propTypes = {
  intl: intlShape.isRequired
}

export default injectIntl(ContactForm);
