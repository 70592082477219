import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";

import withLayout from "../layout";
import SEO from "../components/seo";
import ContactForm from "../components/ContactForm";
import StylizedMap from "../components/Map";

import "./contact.scss";

const ContactPage = (props) => {
    const { intl } = props;
    const titleText = intl.formatMessage({ id: "contact.contact-title" });

    return (
        <div>
            <SEO
                title={titleText}
                keywords={[
                    `brief therapy`,
                    `San Francisco`,
                    `Psychotherapy`,
                    `MFT`,
                ]}
                lang={props.pageContext.locale}
            />
            <article className="contact">
                <h1>
                    <FormattedMessage id="contact.contact-title" />
                </h1>
                <section className="contact__contact">
                    <p>
                        <FormattedMessage
                            id="contact.contact-call"
                            values={{
                                strongIn: <strong>, strongOut: </strong>,
                            }}
                        />
                    </p>
                    <p>
                        <FormattedMessage id="contact.contact-know-you" />
                    </p>
                    <ContactForm />
                </section>
                <section className="contact__location">
                    <h2>
                        <FormattedMessage id="contact.contact-local-title" />
                    </h2>
                    <p>
                        <FormattedMessage id="contact.contact-office" />
                    </p>
                    <p>
                        <FormattedMessage id="contact.contact-get-there" />
                    </p>
                    <StylizedMap />
                </section>
            </article>
        </div>
    );
};

const customProps = {
    localeKey: "contact",
};

export default withLayout(customProps)(injectIntl(ContactPage));
